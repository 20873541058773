import Educations from "./components/Educations";
import Identity from "./components/Identity";
import Experiences from "./components/Experiences";
import Certificates from "./components/Certificates";
import Languages from "./components/Languages";
import Disabilities from "./components/Disabilities";
import Referees from "./components/Referees";
import { Typography } from "@mui/material";
import CVUpload from "./components/CVUpload";
import Publications from "./components/Publications";
import { connect } from "react-redux";

const Profile = (props) => {
  const { user } = props;
  return (
    <div className="row mx-0 mt-4">
      <div className="col-12 mt-sm-0 px-1 px-sm-3">
        <div className="card mb-3 elevated rounded border border-primary bg-light ">
          <div className="card-header bg-light rounded-top font-weight-bold text-uppercase">
            <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between ">
              <Typography
                variant="h6"
                component="h2"
                className="text-blue-main"
              >
                My Profile
              </Typography>
              <Typography
                variant="h6"
                component="span"
                className="text-primary"
                aria-label={`Your employee ID is ${user?.id}`}
              >
                <span aria-hidden="true">Id:{user?.id}</span>
              </Typography>
            </div>
          </div>
          <div className="card-body px-2 px-sm-3">
            <Identity />
            <Educations />
            <Experiences />
            <Certificates />
            <Disabilities />
            <Languages />
            <Publications />
            <Referees />
            <CVUpload showEdit />
          </div>
        </div>
      </div>
      {/* <div className="col-12 mb-3 col-sm-4 col-md-3 px-1 px-sm-3">
        <ProfileSummary />
        <div className="d-none d-md-block">
          <ApplicationGuidelines expanded={true} />
        </div>
        <div className="d-block d-md-none ">
          <ApplicationGuidelines expanded={false} />
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Profile);
