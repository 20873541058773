import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getCertificates,
  getUserCertificates,
  deleteUserCertificate,
  updateUserFile,
} from "../../../../store/profile/actions";
import NoResults from "../../../comm/NoResults";
import colors from "../../../utils/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AlertDialog from "../../../comm/AlertDialog";
import PreviewPdfUrl from "../../../comm/PreviewPdfUrl";
import CertificateForm from "./CertificateForm";
import AdvertSkeleton from "../../../comm/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import LockIcon from "@mui/icons-material/Lock";

export const Certificates = (props) => {
  const {
    env,
    loading,
    onWait,
    userCertificates,
    getUserCertificates,
    deleteUserCertificate,
    updateUserFile,
    lookup,
    getCertificates,
  } = props;


  useEffect(() => {
    getUserCertificates();

    if (!lookup?.certificates?.length) getCertificates();
  }, []);


  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showCertificateForm, setShowCertificateForm] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null);

  return (
    <>
      <Card
        className={`border rounded mb-3`}
        elevation={1}
        id="certificates"
        role="region"
        aria-labelledby="certificateHeading"
      >
        <CardContent>
          {loading && !onWait && !userCertificates?.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography
                    className="mr-2"
                    variant="h6"
                    component="h3"
                    style={{ color: colors.blueLogo }}
                    id="certificateHeading"
                  >
                    Awards/Certificates
                  </Typography>
                </div>

                <Button
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => {
                    setShowCertificateForm(true);
                  }}
                  size="small"
                  aria-label="Add new certificate"
                >
                  <span aria-hidden="true">
                    <span className="d-none d-md-inline mr-1">Add</span> new
                  </span>
                </Button>
              </div>

              {userCertificates.length === 0 ? (
                <NoResults />
              ) : (
                userCertificates.map((certificate, index) => (
                  <CertificateItemCard
                    key={certificate.id}
                    index={index}
                    certificate={certificate}
                    loading={loading}
                    deleteUserCertificate={deleteUserCertificate}
                    setSelectedCertificate={setSelectedCertificate}
                    setShowCertificateForm={setShowCertificateForm}
                    setLoadingDocument={setLoadingDocument}
                    updateUserFile={updateUserFile}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showCertificateForm && (
        <CertificateForm
          onShow={showCertificateForm}
          selectedCertificate={selectedCertificate}
          onClose={() => {
            setShowCertificateForm(false);
            setSelectedCertificate(null);
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  env,
  onWait,
  user,
  lookup,
  certificates,
  userCertificates,
}) => ({
  loading,
  env,
  onWait,
  user,
  lookup,
  certificates,
  userCertificates,
});

export default connect(mapStateToProps, {
  getUserCertificates,
  deleteUserCertificate,
  updateUserFile,
  getCertificates,
})(Certificates);

const CertificateItemCard = (props) => {
  const {
    env,
    loading,
    certificate,
    deleteUserCertificate,
    setSelectedCertificate,
    setShowCertificateForm,
    setLoadingDocument,
    updateUserFile,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {certificate.yearIssued}
            </span>
          }
          title={<Typography>{certificate.certificate.name}</Typography>}
          subheader={
            <span className="btn-link">{certificate.institutionName} </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (certificate.docReferenceId) {
                      setOnViewDocument(true);
                    } else {
                      updateUserFile(
                        certificate,
                        "CHANGE_CERTIFICATE_FILE",
                        certificate.userId +
                          "-" +
                          certificate?.certificate?.name,
                        setOnViewDocument,
                        setLoadingDocument
                      );
                    }
                  }}
                  aria-label="view certificate document"
                >
                  <FilePresentIcon
                    // fontSize="large"
                    style={{
                      color: !!certificate.docReferenceId
                        ? "#fe4066"
                        : "#c57c7cb8",
                    }}
                  />
                </IconButton>
                {certificate.locked && (
                  <IconButton size="small" aria-label="settings">
                    <LockIcon />
                  </IconButton>
                )}
                {(!certificate.locked || env !== "prod") && (
                  <>
                    <IconButton
                      size="small"
                      // className="ml-n2"
                      onClick={handleOpenMenu}
                      aria-label="open certificate menu"
                    >
                      <Badge color="info">
                        <span className="material-icons">more_vert</span>
                      </Badge>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 2,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 15,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setSelectedCertificate(certificate);
                            setShowCertificateForm(true);
                          }}
                          className="text-dark font-weight-light"
                          aria-label="edit certificate"
                        >
                          <span className="material-icons mr-1">edit_note</span>
                          <span aria-hidden="true">Edit</span>
                        </MenuItem>
                      </span>

                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setOnDelete(true);
                          }}
                          className="text-danger font-weight-light"
                          aria-label="delete certificate"
                        >
                          <span className="material-icons mr-1">remove</span>{" "}
                          <span aria-hidden="true">Remove</span>
                        </MenuItem>
                      </span>
                    </Menu>
                  </>
                )}
              </div>
            </>
          }
        />
      </Card>

      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserCertificate(certificate.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this certificate?"
          disabled={loading}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `certificate document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              certificate.docReferenceId,
          }}
        />
      )}
    </>
  );
};
