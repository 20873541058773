import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserProfile,
  updateUserResidence,
  getDistricts,
  getUserProfileStatus,
  getREBApplicantPreferences,
  saveREBApplicantPreferences,
} from "../../../../store/profile/actions";
import moment from "moment";
import MaskedInput from "react-text-mask";
import axiosMiddleware from "../../../utils/axios-middleware";
import axios from "axios";
import { showError } from "../../../toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ID_NUMBER_INPUT_PATTERN = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

export const Profile = (props) => {
  const {
    user,
    loading,
    lookup,
    getDistricts,
    getUserProfile,
    updateUserResidence,
    getUserProfileStatus,
    userProfileStatus,
    getREBApplicantPreferences,
    saveREBApplicantPreferences,
    rebApplicantPreferences,
  } = props;

  const [wait, setWait] = useState(false);

  // const [changePassword, setChangePassword] = useState(false);

  const [userResidence, setUserResidence] = useState({});

  const [sectors, setSectors] = useState([]);
  const [cells, setCells] = useState([]);
  const [villages, setVillages] = useState([]);

  const [district, setDistrict] = useState(null);
  const [sector, setSector] = useState(null);
  const [cell, setCell] = useState(null);
  const [village, setVillage] = useState(null);

  const [nidLoading, setNidLoading] = useState(false);
  const [nidError, setNidError] = useState(false);

  const [formData, setFormData] = useState({
    idNumber: "",
    lastName: "",
    firstName: "",
    fatherNames: "",
    motherNames: "",
    placeOfBirth: "",
    phoneNumber: "",
    genderId: "",
    dateOfBirth: "",
    verified: false,
    profileImage: null,
  });

  const onNIDChange = (e) => {
    const newValue = e.target.value;

    setNidError(false);
    setFormData({ ...formData, idNumber: newValue, verified: false });

    if (newValue.length === 21) verifyNationalID(newValue);
  };

  const verifyNationalID = async (idNumber) => {
    try {
      setNidLoading(true);

      const { data } = await axiosMiddleware.get(
        `/api/profile/user-identity/${(idNumber || "").replace(/\s/g, "")}`
      );

      setFormData({
        ...formData,
        idNumber: idNumber,
        lastName: data.lastName,
        firstName: data.firstName,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        placeOfBirth: data.placeOfBirth,
        genderId: data.genderId,
        dateOfBirth: data.dateOfBirth,
        phoneNumber: user.phoneNumber,
        verified: true,
      });

      getSectors(data.residence?.cell?.sector?.districtId);
      getCells(data.residence?.cell?.sectorId);
      getVillages(data.residence?.cellId);

      setVillage(data.residence || null);
      setCell(data.residence?.cell || null);
      setSector(data.residence?.cell?.sector || null);
      setDistrict(data.residence?.cell?.sector?.district || null);

      setNidError(false);
      setNidLoading(false);
    } catch (error) {
      setNidError(true);
      setNidLoading(false);
      showError(error);
    }
  };

  useEffect(() => {
    setFormData({
      ...formData,
      idNumber: user.idNumber,
      lastName: user.lastName,
      firstName: user.firstName,
      fatherNames: user.fatherNames,
      motherNames: user.motherNames,
      placeOfBirth: user.placeOfBirth,
      phoneNumber: user.phoneNumber,
      genderId: user.genderId,
      dateOfBirth: user.dateOfBirth,
      verified: user.nidVerified,
      profileImage: user.profileImage,
    });

    if (!user.nidVerified) setNidError(true);

    // if ((user.idNumber || "").replace(/\s/g, "").length === 16)
    //   verifyNationalID(user.idNumber);

    getREBApplicantPreferences();

    if (!lookup.districts) getDistricts();
    getUserProfile(setUserResidence);
  }, []);

  useEffect(() => {
    if (!!userResidence) {
      if (!!userResidence?.cell?.sector?.districtId)
        getSectors(userResidence?.cell?.sector?.districtId);
      if (!!userResidence?.cell?.sectorId)
        getCells(userResidence?.cell?.sectorId);
      if (!!userResidence?.cellId) getVillages(userResidence?.cellId);

      setVillage(userResidence || null);
      setCell(userResidence?.cell || null);
      setSector(userResidence?.cell?.sector || null);
      setDistrict(userResidence?.cell?.sector?.district || null);
    }
  }, [userResidence]);

  useEffect(() => {
    if (!!district?.id) getSectors(district.id);
  }, [district]);

  useEffect(() => {
    if (!!sector?.id) getCells(sector.id);
  }, [sector]);

  useEffect(() => {
    if (!!cell?.id) getVillages(cell.id);
  }, [cell]);

  const handleSubmitChanges = () => {
    updateUserResidence(
      {
        residenceId: village.id,
        idNumber: (formData.idNumber || "").replace(/\s/g, ""),
        phoneNumber: formData.phoneNumber || "",
        // examDistrict: undefined,
        // workDistricts: undefined,
        // didEducation: undefined,
      },
      setUserResidence,
      getUserProfileStatus
    );
  };

  const residentChanged = () => {
    return (
      (!!village && userResidence?.id !== village?.id) ||
      user.idNumber !== formData.idNumber ||
      user.phoneNumber !== formData.phoneNumber ||
      userProfileStatus.verified !== formData.verified
    );
  };

  const getSectors = async (districtId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/sectors/` + districtId);
      setSectors(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  const getCells = async (sectorId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/cells/` + sectorId);
      setCells(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  const getVillages = async (cellId) => {
    try {
      setWait(true);
      const { data } = await axios.get(`/api/lookup/villages/` + cellId);
      setVillages(data);
      setWait(false);
    } catch (error) {
      setWait(false);
      showError(error);
    }
  };

  return (
    <>
      <Card
        className={`border rounded mb-3`}
        elevation={1}
        role="region"
        aria-labelledby="identityHeading"
      >
        <CardContent>
          <div className="container-fluid">
            <ApplicantPreferences
              lookup={lookup}
              loading={loading}
              saveREBApplicantPreferences={saveREBApplicantPreferences}
              rebApplicantPreferences={rebApplicantPreferences}
            />
            <Typography
              variant="h6"
              component="h3"
              className="text-blue-main mt-1 ml-1 text-uppercase"
              id="identityHeading"
            >
              Identity
            </Typography>

            <div className="row justify-content-center ">
              <div className="col-12">
                {/* <div className="card border-primary mb-3"> */}
                <div className="row align-items-center justify-content-center">
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    {!formData.verified && !nidLoading && !nidError && (
                      <span className="material-icons loaderIcon text-info">
                        help
                      </span>
                    )}

                    {nidLoading && (
                      <i
                        className="fas fa-spinner loaderIcon text-primary loader2"
                        style={{ fontSize: "22px" }}
                      ></i>
                    )}

                    {!nidLoading && !!nidError && (
                      <span className="loaderIcon text-danger d-flex align-items-center">
                        <span className="material-icons text-danger">
                          report_problem
                        </span>
                      </span>
                    )}

                    {formData.verified && !nidLoading && !nidError && (
                      <span className="loaderIcon text-success d-flex align-items-center">
                        <span className="material-icons text-success ">
                          verified
                        </span>
                      </span>
                    )}
                    <MaskedInput
                      mask={ID_NUMBER_INPUT_PATTERN}
                      className="form-control"
                      placeholder="Enter your NID Number"
                      guide={false}
                      value={formData.idNumber}
                      onChange={onNIDChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && e.target.value.length === 21) {
                          verifyNationalID(e.target.value);
                        }
                      }}
                      aria-label="Enter your NID Number"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Last Name"
                      value={formData.lastName}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="First Name"
                      value={formData.firstName}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Father Name"
                      value={formData.fatherNames || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Mother Name"
                      value={formData.motherNames || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Place of Birth"
                      value={formData.placeOfBirth || ""}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Gender"
                      value={formData.genderId === "M" ? "Male" : "Female"}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Date of Birth"
                      value={
                        !!formData.dateOfBirth
                          ? moment(formData.dateOfBirth).format("ll")
                          : ""
                      }
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      style={{ backgroundColor: "#eee" }}
                      label="Email"
                      value={user.email}
                      size="small"
                      InputProps={{
                        readOnly: true,
                      }}
                      className="w-100"
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <TextField
                      label="Phone Number"
                      value={formData.phoneNumber || ""}
                      size="small"
                      className={`w-100`}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          phoneNumber: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={district}
                      options={lookup.districts || []}
                      disabled={loading}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, district) => {
                        setDistrict(district || null);
                        setSector(null);
                        setCell(null);
                        setVillage(null);

                        setSectors([]);
                        setCells([]);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, district) => (
                        <Box component="li" {...props} key={district.id}>
                          {district.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence district"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={sector}
                      options={sectors}
                      disabled={loading || !sectors.length}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, sector) => {
                        setSector(sector || null);
                        setCell(null);
                        setVillage(null);

                        setCells([]);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, sector) => (
                        <Box component="li" {...props} key={sector.id}>
                          {sector.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence sector"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={cell || null}
                      options={cells}
                      disabled={loading || !cells.length}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, cell) => {
                        setCell(cell || null);
                        setVillage(null);
                        setVillages([]);
                      }}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, cell) => (
                        <Box component="li" {...props} key={cell.id}>
                          {cell.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence cell"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-4 mb-2 mt-2">
                    <Autocomplete
                      size="small"
                      defaultValue={null}
                      value={village || null}
                      options={villages}
                      disabled={loading || !villages.length}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, village) => setVillage(village || null)}
                      getOptionLabel={(option) => option.name || ""}
                      renderOption={(props, village) => (
                        <Box component="li" {...props} key={village.id}>
                          {village.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            height: "25px !important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px !important",
                          }}
                          {...params}
                          label="Residence village"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 border-right-sm">
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      type="button"
                      className={`btn btn-primary btn-sm w-100`}
                      disabled={loading || !formData.verified}
                      onClick={handleSubmitChanges}
                      aria-label="Update your residence"
                    >
                      <span aria-hidden="true">Update</span>
                    </Button>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* {changePassword && (
        <ChangePassword
          onShow={changePassword}
          onClose={() => setChangePassword(false)}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  lookup,
  userProfileStatus,
  rebApplicantPreferences,
}) => ({
  user,
  loading,
  lookup,
  userProfileStatus,
  rebApplicantPreferences,
});

export default connect(mapStateToProps, {
  getUserProfile,
  updateUserResidence,
  getDistricts,
  getUserProfileStatus,
  getREBApplicantPreferences,
  saveREBApplicantPreferences,
})(Profile);

const ApplicantPreferences = (props) => {
  const {
    lookup,
    loading,
    saveREBApplicantPreferences,
    rebApplicantPreferences,
  } = props;

  const [examDistrict, setExamDistrict] = useState(null);
  const [workDistricts, setWorkDistricts] = useState([]);
  const [didEducation, setDidEducation] = useState("");

  const [showREBPreferences, setShowREBPreferences] = useState(true);

  useEffect(() => {
    if (rebApplicantPreferences.examDistrict)
      setExamDistrict(rebApplicantPreferences.examDistrict);

    if (rebApplicantPreferences.workDistricts)
      setWorkDistricts(rebApplicantPreferences.workDistricts);

    if (rebApplicantPreferences.examDistrict)
      setDidEducation(!!rebApplicantPreferences.didEducation ? "Yes" : "No");

    if (!!rebApplicantPreferences.examDistrict) setShowREBPreferences(false);
  }, [rebApplicantPreferences]);

  return (
    <div
      className="row mx-0 p-2 rounded border border-secondary align-items-center justify-content-center"
      style={{
        backgroundColor: "#00ffa14f",
      }}
      role="region"
      aria-labelledby="rebPreferencesHeading"
    >
      <div className="col-12 d-flex justify-content-between align-items-center">
        <Typography
          variant="subtitle2"
          component="h4"
          id="rebPreferencesHeading"
        >
          Only for REB and RTB applicants
        </Typography>
        {!!rebApplicantPreferences.examDistrict && (
          <>
            <IconButton
              onClick={() => setShowREBPreferences(!showREBPreferences)}
              size="small"
              color="primary"
            >
              {!!showREBPreferences && (
                <span className="material-icons text-danger">close</span>
              )}
              {!showREBPreferences && (
                <span className="material-icons">edit_note</span>
              )}
            </IconButton>
          </>
        )}
      </div>
      <div className="col-12 col-lg-4 mb-2 mt-3">
        {!!showREBPreferences && (
          <Autocomplete
            fullWidth
            size="small"
            defaultValue={null}
            value={examDistrict}
            options={lookup.districts || []}
            disabled={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, district) => {
              setExamDistrict(district || null);
            }}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, district) => (
              <Box component="li" {...props} key={district.id}>
                {district.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                size="small"
                style={{
                  height: "25px !important",
                  position: "relative",
                  borderRadius: "8px",
                  padding: "1px !important",
                }}
                {...params}
                label="Select district to sit for exam"
                placeholder="Select district to sit for exam"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "examDistrict", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}

        {!showREBPreferences && (
          <div>
            Selected district to sit for exam
            <div className="font-weight-bold">1:{examDistrict.name}</div>
          </div>
        )}
      </div>
      <div className="col-12 col-lg-4 mb-2 mt-2">
        {!!showREBPreferences && (
          <Autocomplete
            multiple
            disableCloseOnSelect={workDistricts.length !== 2}
            disablePortal
            fullWidth
            size="small"
            defaultValue={null}
            value={workDistricts}
            options={lookup.districts || []}
            disabled={loading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, districts) => {
              if (districts.length <= 3) setWorkDistricts(districts || []);
            }}
            getOptionLabel={(option) => option.name || ""}
            renderOption={(props, district, { selected }) => (
              <Box component="li" {...props} key={district.id}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {district.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                size="small"
                style={{
                  height: "25px !important",
                  position: "relative",
                  borderRadius: "8px",
                  padding: "1px !important",
                }}
                {...params}
                label="Select 3 districts to work in"
                placeholder="Select 3 districts to work in"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "workDistricts", // disable autocomplete and autofill
                }}
              />
            )}
          />
        )}

        {!showREBPreferences && (
          <div>
            Selected districts to work in:
            {workDistricts.map((district, index) => (
              <div className="font-weight-bold" key={index}>
                {index + 1}:{district.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="col-12 col-lg-4 mb-2">
        <label>Have you studied education?</label> <br />
        {!!showREBPreferences && (
          <div className="form-check form-check-inline">
            <input
              className="form-check-input mr-0"
              type="radio"
              name="radio_btn"
              id="inlineRadio1"
              value="Yes"
              checked={didEducation === "Yes"}
              onChange={(e) => {
                if (!!showREBPreferences) setDidEducation(e.target.value);
              }}
            />
            <label className="form-check-label pl-1" htmlFor="inlineRadio1">
              Yes
            </label>
          </div>
        )}
        {!!showREBPreferences && (
          <div className="form-check form-check-inline">
            <input
              className="form-check-input mr-0"
              type="radio"
              name="radio_btn"
              id="inlineRadio2"
              value="No"
              checked={didEducation === "No"}
              onChange={(e) => {
                if (!!showREBPreferences) setDidEducation(e.target.value);
              }}
            />
            <label className="form-check-label pl-1" htmlFor="inlineRadio2">
              No
            </label>
          </div>
        )}
        {!showREBPreferences && (
          <div className="font-weight-bold"> {didEducation}</div>
        )}
      </div>
      {!!showREBPreferences && (
        <div className="col-12 col-md-6 col-lg-4">
          <button
            disabled={
              !examDistrict ||
              workDistricts.length !== 3 ||
              !didEducation ||
              loading
            }
            type="button"
            className="btn btn-primary btn-sm btn-block"
            onClick={() => {
              saveREBApplicantPreferences(
                {
                  examDistrictId: examDistrict?.id,
                  workDistrictId1: workDistricts[0]?.id,
                  workDistrictId2: workDistricts[1]?.id,
                  workDistrictId3: workDistricts[2]?.id,
                  didEducation: didEducation === "Yes" ? true : false,
                },
                () => setShowREBPreferences(false)
              );
            }}
            aria-label="Save your preferences"
          >
            <span aria-hidden="true">Save</span>
          </button>
        </div>
      )}
    </div>
  );
};
