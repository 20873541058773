import React, { useState, useEffect } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import loadingGif from "./assets/loading.gif";
import TopProgressBar from "./comm/TopProgressBar";
import ToastifyMessage from "./comm/ToastifyMessage";
import Home from "./home/index";
import Header from "./comm/Header";
import Footer from "./comm/Footer";
import LoginModal from "./home/components/LoginModal";
import RegisterModal from "./home/components/RegisterModal";
import { getUser, getEnv } from "../store/comm/actions";
import { connect } from "react-redux";
import ApplicantDrawer from "./comm/Drawer";
import ApplicantLayout from "./applicant/index";
import { isEmpty } from "lodash";
import OfflineMessage from "./comm/OfflineMessage";
import ResetPassword from "./home/ResetPassword";
import EnvMessage from "./comm/EnvMessage";

const SkipLink = () => (
  <a href="#main-content" className="sr-only">
    Skip to main content
  </a>
);

function App(props) {
  const { env, user, getUser, isFetchingUser, getEnv } = props;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getEnv();
    getUser(history, location.pathname);
  }, []);

  // useEffect(() => {
  //   if (isEmpty(user) && !isFetchingUser) return history.push("/");
  // }, []);

  const [drawerState, setDrawerState] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  // useEffect(() => {
  //   if (!!showLoginDialog) setShowRegisterDialog(false);
  //   if (!!showRegisterDialog) setShowLoginDialog(false);
  // }, [showLoginDialog, showRegisterDialog]);

  return (
    <>
      {isFetchingUser && (
        <div className="loader">
          <img src={loadingGif} height="128" alt="loading" />
        </div>
      )}

      <SkipLink
        sectionId={
          location.pathname.includes("/exam-portal")
            ? "main-content-exam"
            : "main-content"
        }
      />

      <div
        id="main"
        style={{
          overflowX: "visible",
          visibility: isFetchingUser ? "hidden" : "visible",
        }}
      >
        {/* <div className="w-100 border-bottom border-danger">
            <Alert severity="error" onClose={() => {}}>
              You have been blacklisted!
            </Alert>
          </div> */}

        <TopProgressBar />
        <ToastifyMessage />

        {!!env && env !== "prod" && <EnvMessage env={env} />}

        <OfflineMessage />

        {!location.pathname.includes("/exam-portal") && (
          <Header
            drawerState={drawerState}
            setDrawerState={setDrawerState}
            showLoginDialog={showLoginDialog}
            setShowLoginDialog={setShowLoginDialog}
            showRegisterDialog={showRegisterDialog}
            setShowRegisterDialog={setShowRegisterDialog}
          >
            {!isEmpty(user) && !location.pathname.includes("/exam-portal") && (
              <ApplicantDrawer
                drawerState={drawerState}
                setDrawerState={setDrawerState}
              />
            )}
          </Header>
        )}

        <main role="main" className="mt-2" tabIndex="-1" id="main-content">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/search" component={Home} />
            <Route path="/applicant" component={ApplicantLayout} />
            <Route path="/reset-password" component={ResetPassword} />
          </Switch>
        </main>

        {!location.pathname.includes("/exam-portal") && <Footer />}

        {!location.pathname.includes("/exam-portal") && showLoginDialog && (
          <LoginModal
            showDialog={showLoginDialog}
            setShowDialog={setShowLoginDialog}
            showRegisterDialog={showRegisterDialog}
            setShowRegisterDialog={setShowRegisterDialog}
          />
        )}
        {!location.pathname.includes("/exam-portal") && showRegisterDialog && (
          <RegisterModal
            showDialog={showRegisterDialog}
            setShowDialog={setShowRegisterDialog}
            showLoginDialog={showLoginDialog}
            setShowLoginDialog={setShowLoginDialog}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = ({ user, loading, isFetchingUser, env }) => {
  return { user, loading, isFetchingUser, env };
};
export default connect(mapStateToProps, { getUser, getEnv })(App);
