import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import AdvertSkeleton from "../comm/AdvertSkeleton";
import NoResults from "../comm/NoResults";
import { getAdvertisements } from "../../store/applicant/actions";
import AdvertisementItem from "../applicant/components/AdvertisementItem";
import { Typography } from "@mui/material";
import RightSideContent from "./components/RightSideContent";
import { useLocation } from "react-router-dom";
import { decryptData } from "../utils";

const Home = (props) => {
  const { loading, onWait, user, advertisements, getAdvertisements } = props;
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [externalAdvert, setExternalAdvert] = useState(null);

  const getExternalAdvert = async () => {
    const queryParameters = new URLSearchParams(location.search);
    const data = queryParameters.get("q");
    if (!!data) {
      const _data = await decryptData(queryParameters.get("q"));
      setExternalAdvert(_data);
    }
  };

  useEffect(() => {
    if (!advertisements.length) getAdvertisements("open");
    getExternalAdvert();
  }, []);

  useEffect(() => {
    if (advertisements) {
      const data = advertisements.filter((item) => !item.isTimeOut);

      if (searchInput !== "") {
        const value = searchInput?.toLowerCase();
        setFilteredJobs(
          data.filter((item) =>
            (
              (item.positionName || "") +
              (item.entityName || "") +
              (item.entityAcronym || "")
            )
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(value.replace(/\s/g, "").toLowerCase())
          )
        );
      } else {
        setFilteredJobs(data);
      }
    }
  }, [advertisements, searchInput]);

  if (!isEmpty(user)) return <Redirect push to="/applicant/advertisements" />;

  const randomArray = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 10)
  );

  return (
    <React.Fragment>
      <section aria-labelledby="home-title" className="container-fluid px-0">
        <div
          className="card"
          style={{ borderRadius: "0px", backgroundColor: "#078ece" }}
        >
          <div className="card-body pt-5" style={{ borderRadius: "0px" }}>
            <div className="row">
              <div className="col text-center">
                <h2
                  id="home-title"
                  className="text-uppercase text-center"
                  style={{
                    fontWeight: "50",
                    color: "#f1f1f1",
                    fontSize: "xx-large",
                  }}
                >
                  Welcome to Rwanda Civil Service Recruitment Portal
                </h2>

                <div className="row justify-content-center mt-5 mb-3">
                  <form
                    className="form-group has-search col-12 col-md-8 col-lg-4"
                    onSubmit={(e) => e.preventDefault()}
                    id="search"
                    role="search"
                  >
                    <span className="fa fa-search form-control-feedback"></span>
                    <label htmlFor="search-public-input" className="sr-only">
                      Search for job advertisements
                    </label>
                    <input
                      type="search"
                      className={`search-input rounded border`}
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      id="search-public-input"
                      name="search-public-input"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="row mx-0 justify-content-center">
        <aside className="col-12 col-md-5 col-lg-3 order-sm-last px-1 px-sm-3">
          <RightSideContent />
        </aside>

        <div className="col-12  col-md-7 col-lg-9 order-sm-first px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary mt-4 bg-light">
            <div className="card-header bg-light rounded-top font-weight-bold text-uppercase">
              <Typography
                variant="h6"
                component="h3"
                className="font-weight-bold"
                aria-label={`${advertisements.length} job advertisements found.`}
                id="job-advertisements-title"
              >
                <span aria-hidden="true">
                  New Job Advertisements ({advertisements.length})
                </span>
              </Typography>
            </div>
            <section
              className="card-body"
              aria-labelledby="job-advertisements-title"
            >
              {loading && !onWait && !advertisements.length ? (
                randomArray.map((item) => (
                  <div className="mb-3" key={item + Math.random() * 11}>
                    <AdvertSkeleton />
                  </div>
                ))
              ) : filteredJobs.length ? (
                filteredJobs.map((advertisement, index) => (
                  <div className="mb-3" key={advertisement.id}>
                    <AdvertisementItem
                      advertisement={advertisement}
                      isHome
                      externalAdvert={
                        externalAdvert && externalAdvert.id === advertisement.id
                          ? externalAdvert
                          : null
                      }
                    />
                  </div>
                ))
              ) : (
                <NoResults />
              )}
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ loading, onWait, user, advertisements }) => {
  return { loading, onWait, user, advertisements };
};
export default connect(mapStateToProps, {
  getAdvertisements,
})(Home);
