import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getUserEducations,
  deleteUserEducation,
  updateUserFile,
} from "../../../../store/profile/actions";
import NoResults from "../../../comm/NoResults";
import colors from "../../../utils/colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AlertDialog from "../../../comm/AlertDialog";
import EducationFormDialog from "./EducationFormDialog";
import PreviewPdfUrl from "../../../comm/PreviewPdfUrl";
import AdvertSkeleton from "../../../comm/AdvertSkeleton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import LockIcon from "@mui/icons-material/Lock";

export const Educations = (props) => {
  const {
    user,
    env,
    loading,
    onWait,
    userEducations,
    getUserEducations,
    deleteUserEducation,
    updateUserFile,
  } = props;

  useEffect(() => {
    getUserEducations();
  }, []);

  // const [viewDocument, setViewDocument] = useState(null);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [showEducationFormDialog, setShowEducationFormDialog] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  return (
    <>
      <Card
        className={`border rounded mb-3`}
        elevation={1}
        role="region"
        aria-labelledby="educationHeading"
      >
        <CardContent>
          {loading && !onWait && !userEducations.length ? (
            <AdvertSkeleton />
          ) : (
            <div className="container-fluid">
              <div className="d-flex flex-row justify-content-between align-items-center bg-light border px-3 py-1">
                <div>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: colors.blueLogo }}
                    className="mr-1"
                    id="educationHeading"
                  >
                    Education background
                  </Typography>
                </div>
                <Button
                  size="small"
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  disabled={loading}
                  onClick={() => setShowEducationFormDialog(true)}
                  aria-label="Add new education"
                >
                  <span aria-hidden="true">
                    <span className="d-none d-md-inline mr-1">Add</span> new
                  </span>
                </Button>
              </div>

              {userEducations.length === 0 ? (
                <NoResults />
              ) : (
                userEducations.map((education, index) => (
                  <EducationItemCard
                    key={education.id}
                    education={education}
                    index={index}
                    loading={loading}
                    deleteUserEducation={deleteUserEducation}
                    setSelectedEducation={setSelectedEducation}
                    setShowEducationFormDialog={setShowEducationFormDialog}
                    setLoadingDocument={setLoadingDocument}
                    updateUserFile={updateUserFile}
                    env={env}
                  />
                ))
              )}
            </div>
          )}
        </CardContent>
      </Card>

      {showEducationFormDialog && (
        <EducationFormDialog
          onShow={showEducationFormDialog}
          selectedEducation={selectedEducation}
          onClose={() => {
            setShowEducationFormDialog(false);
            setSelectedEducation(null);
          }}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loadingDocument ? "d-block" : "d-none"
        }`}
      >
        {loadingDocument && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user, env, loading, onWait, userEducations }) => ({
  user,
  env,
  loading,
  onWait,
  userEducations,
});

export default connect(mapStateToProps, {
  getUserEducations,
  deleteUserEducation,
  updateUserFile,
})(Educations);

// connect(mapStateToProps, {
//   getUserEducations,
//   deleteUserEducation,
//   updateUserFile,
// })();

const EducationItemCard = (props) => {
  const {
    loading,
    env,
    education,
    deleteUserEducation,
    setSelectedEducation,
    setShowEducationFormDialog,
    setLoadingDocument,
    updateUserFile,
  } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);
  const [onDelete, setOnDelete] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        className={`mb-2 rounded hoverable-card `}
        elevation={0}
        variant="outlined"
        style={{
          backgroundColor: "#fff",
          border: "none",
        }}
      >
        <CardHeader
          avatar={
            <span className="font-weight-bold text-primary">
              {education?.graduationYear}
            </span>
          }
          title={<Typography>{education?.qualification?.name}</Typography>}
          subheader={
            <span>
              <span className="btn-link">{education?.school?.name}</span> /{" "}
              <strong>{education.school?.country?.name}</strong>
            </span>
          }
          action={
            <>
              <div
                className="d-flex flex-row align-items-center "
                style={{ borderRadius: "4px" }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    if (education.docReferenceId) {
                      setOnViewDocument(true);
                    } else {
                      updateUserFile(
                        education,
                        "CHANGE_EDUCATION_FILE",
                        education.userId + "-" + education.qualification.name,
                        setOnViewDocument,
                        setLoadingDocument
                      );
                    }
                  }}
                  aria-label={`view ${education.qualification.name} document`}
                >
                  <FilePresentIcon
                    // fontSize="large"
                    style={{
                      color: !!education.docReferenceId
                        ? "#fe4066"
                        : "#c57c7cb8",
                    }}
                  />
                </IconButton>
                {education.locked && (
                  <IconButton size="small" aria-label="settings">
                    <LockIcon />
                  </IconButton>
                )}
                {(!education.locked || env !== "prod") && (
                  <>
                    <IconButton
                      size="small"
                      onClick={handleOpenMenu}
                      aria-label="settings"
                    >
                      <Badge color="info">
                        <span className="material-icons">more_vert</span>
                      </Badge>
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 2,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 15,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setSelectedEducation(education);
                            setShowEducationFormDialog(true);
                          }}
                          className="text-dark font-weight-light"
                        >
                          <span className="material-icons mr-1">edit_note</span>
                          Edit
                        </MenuItem>
                      </span>

                      <span>
                        <MenuItem
                          disabled={loading}
                          onClick={() => {
                            handleCloseMenu();
                            setOnDelete(true);
                          }}
                          className="text-danger font-weight-light"
                        >
                          <span className="material-icons mr-1">remove</span>{" "}
                          Remove
                        </MenuItem>
                      </span>
                    </Menu>
                  </>
                )}
              </div>
            </>
          }
        />
      </Card>
      <Divider variant="fullWidth" />
      <Divider variant="fullWidth" />

      {onDelete && (
        <AlertDialog
          status={onDelete}
          handleClose={() => setOnDelete(false)}
          handleConfirm={() =>
            deleteUserEducation(education.id, () => setOnDelete(false))
          }
          title="Delete qualification"
          desc="Are your sure you want to delete this qualification?"
          disabled={loading}
        />
      )}

      {onViewDocument && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `Education document`,
            url:
              process.env.REACT_APP_FILES_URL +
              "/preview/" +
              education.docReferenceId,
          }}
        />
      )}
    </>
  );
};
