/**
 * Decrypts an encrypted string
 * @param {string} encryptedData - Encrypted string (Base64)
 * @returns {Promise<string|object>} - Decrypted text or object
 */
export const decryptData = async (encodedText) => {
  try {
    const data = decodeURIComponent(escape(atob(encodedText)));
    // Parse JSON if possible
    try {
      return JSON.parse(data);
    } catch {
      return data;
    }
  } catch (error) {
    console.error("Decryption Error:", error);
    return null;
  }
};
