import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import colors from "../../utils/colors";
import moment from "moment/moment";
import CountDownTimer from "../exam-portal/components/CountDownTimer";
import { useDispatch } from "react-redux";
import AdvertisementDetails from "./AdvertisementDetails";
function AdvertisementItem(props) {
  const {
    advertisement,
    hideTime,
    roundedValue,
    isForView,
    isHome,
    externalAdvert = null,
  } = props;

  const dispatch = useDispatch();

  const [showAdvertisementDetails, setShowAdvertisementDetails] = useState(
    externalAdvert ? true : false
  );

  return (
    <section aria-labelledby={`advertisement-title-${advertisement.id}`}>
      <Card
        className={`border ${roundedValue || "rounded"} bg-light w-full h-100`}
        elevation={isForView ? 0 : 1}
      >
        <CardHeader
          className="pb-0"
          title={
            <button
              onClick={() => {
                if (!isForView) setShowAdvertisementDetails(true);
              }}
              className="btn btn-link px-0 d-flex justify-content-between text-underline"
              aria-label={`Position details for ${advertisement.positionName} ${
                advertisement.entityName ? "at " + advertisement.entityName : ""
              }`}
              id={`advertisement-title-${advertisement.id}`}
            >
              <Typography
                sx={{ fontSize: 16 }}
                variant="h2"
                component={"h3"}
                color={colors.blueLogo}
                style={{ fontWeight: 500 }}
                aria-hidden="true"
              >
                {advertisement.positionName}
              </Typography>
            </button>
          }
          subheader={
            <Typography variant="h6" component="h4" sx={{ fontSize: 13 }}>
              <span className="text-capitalize">
                {advertisement.entityName?.toLowerCase()}{" "}
                {!!advertisement.entityAcronym && (
                  <>({advertisement.entityAcronym})</>
                )}
              </span>
            </Typography>
          }
          action={
            <span>
              {((!isForView && !advertisement.wasApplied) || isHome) && (
                <Button
                  size="small"
                  color="primary"
                  className="py-0"
                  variant="contained"
                  onClick={() => setShowAdvertisementDetails(true)}
                  aria-label={`Apply for ${advertisement.positionName} ${
                    advertisement.entityName
                      ? "at " + advertisement.entityName
                      : ""
                  }`}
                >
                  <span aria-hidden="true">Apply</span>
                </Button>
              )}

              {advertisement.wasApplied && !isHome && (
                <Button
                  size="small"
                  color="primary"
                  className="py-0"
                  variant="contained"
                  disabled
                  aria-label={`You have already applied for ${
                    advertisement.positionName
                  }${
                    advertisement.entityName
                      ? " at " + advertisement.entityName
                      : ""
                  }`}
                >
                  <span aria-hidden="true">Applied</span>
                </Button>
              )}
            </span>
          }
        />
        <CardContent className="pt-0">
          <div className="d-flex align-items-center flex-wrap">
            <div className="mr-2" aria-hidden={isForView ? "false" : "true"}>
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Level:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {`${advertisement.levelName}.${advertisement.scaleName}`}
              </Typography>
            </div>
            <div className="mr-2" aria-hidden={isForView ? "false" : "true"}>
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Post{advertisement.numberOfPosts > 1 ? "s" : ""}:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {advertisement.numberOfPosts}
              </Typography>
            </div>
            <span aria-hidden={isForView ? "false" : "true"}>
              <span
                className={`badge badge-pill border`}
                style={{ fontSize: 10 }}
              >
                {advertisement.employeeGroupName}
              </span>{" "}
              {advertisement.isInternal && (
                <span className="badge badge-info ml-2">Internal</span>
              )}
            </span>
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center flex-wrap">
            <div
              className="d-flex flex-nowrap"
              aria-hidden={isForView ? "false" : "true"}
            >
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Posted on</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(advertisement.openingDate).format("ll")}
                </Typography>
              </div>

              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <AccessTimeOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Deadline</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(advertisement.closingDate).format("ll")}
                </Typography>
              </div>
            </div>
            <div
              className="mx-auto mx-md-0 mt-2 mb-n3 mt-1 mt-md-0"
              aria-hidden={isForView ? "false" : "true"}
            >
              {!hideTime && (
                <CountDownTimer
                  counterType="small"
                  defaultLeftDifferent={
                    advertisement.closingTimeLeft
                      ? advertisement.closingTimeLeft
                      : 0
                  }
                  onTimeOut={() =>
                    dispatch({
                      type: "DELETE_ADVERTISEMENT",
                      id: advertisement.id,
                    })
                  }
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {showAdvertisementDetails && (
        <AdvertisementDetails
          isHome={isHome}
          showDialog={showAdvertisementDetails}
          setShowDialog={setShowAdvertisementDetails}
          advertisement={advertisement}
          externalAdvert={externalAdvert}
        />
      )}
    </section>
  );
}

export default AdvertisementItem;
